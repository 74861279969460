import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { roundToNearest } from '../../helpers/roundToNearest'

const SchemaAggregateRating = ({ total, max, average }) => {
  const averagePercent = roundToNearest((average / 5) * 100, 1)
  const maxPercent = (max / 5) * 100

  const contactDetails = useStaticQuery(
    graphql`
      {
        global: sanityGlobalSettings {
          streetAddress
          addressLocality
          addressRegion
          postalCode
          hotelImage {
            asset {
              gatsbyImage(width: 1200, height: 600)
            }
          }
        }
        header: sanityGlobalHeader {
          tel
        }
      }
    `
  )

  const review = {
    '@context': 'https://schema.org/',
    '@type': 'AggregateRating',
    itemReviewed: {
      '@type': 'Hotel',
      name: 'Soul Valley',
      priceRange: '€€€',
      image: contactDetails.global.hotelImage.asset.gatsbyImage.images.fallback.src,
      telephone: contactDetails.header.tel,
      address: {
        '@type': 'PostalAddress',
        streetAddress: contactDetails.global.streetAddress,
        addressLocality: contactDetails.global.addressLocality,
        addressRegion: contactDetails.global.addressRegion,
        postalCode: contactDetails.global.postalCode,
        addressCountry: 'IT',
      },
    },
    ratingValue: averagePercent,
    bestRating: maxPercent,
    ratingCount: total,
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(review) }}
    />
  )
}

export default SchemaAggregateRating

SchemaAggregateRating.propTypes = {
  total: PropTypes.number,
  max: PropTypes.number,
  average: PropTypes.number,
}

SchemaAggregateRating.defaultProps = {
  total: '',
  max: '',
  average: '',
}
